/* eslint-disable react/jsx-max-depth */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { PreviewCommunication } from '@/components/content/PreviewCommunication';
import { Settings } from '@/data/Settings';
import {
	BV_CLIENT_NAME_10851,
	BV_CLIENT_NAME_10852,
	BV_CLIENT_NAME_10853,
	BV_LOCALE_10851,
	BV_LOCALE_10852,
	BV_LOCALE_10853,
	BV_SITE_ID_10851,
	BV_SITE_ID_10852,
	BV_SITE_ID_10853,
} from '@/data/constants/BaazarVoice';
import { KLAVIYO_ID_10851, KLAVIYO_ID_10852, KLAVIYO_ID_10853 } from '@/data/constants/store';
import { EventsProvider } from '@/data/context/events';
import { GlobalSpinnerProvider } from '@/data/context/globalSpinner';
import { HeaderAccountMenuProvider } from '@/data/context/headerAccountMenu';
import { NotificationsProvider } from '@/data/context/notifications';
import { SessionErrorProvider } from '@/data/context/sessionError';
import { EMPTY_SETTINGS, SettingProvider } from '@/data/context/setting';
import { getGTMConfig, initializeGTM } from '@/data/events/handlers/gtm';
import { StateProvider } from '@/data/state/provider';
import { createEmotionCache } from '@/utils/createEmotionCache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect, useMemo, useState } from 'react';

type pageProps = {
	fallback: any;
	settings: Settings;
	csrSession: boolean;
};
interface MyAppProps extends AppProps<pageProps> {
	emotionCache?: EmotionCache;
	settings: Settings;
}
const clientSideEmotionCache = createEmotionCache();

const MyApp = ({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) => {
	// store setting only need to fetch once ideally
	const [settings, setSettings] = useState<Settings>(() => pageProps.settings);
	useEffect(() => {
		setSettings((prevSettings) => prevSettings ?? pageProps.settings);
		// ---------------------------------------------------------------------------//
		// Google Tag Manager Integration
		// ---------------------------------------------------------------------------//
		// Right now - the configuration we need from STORECONF isn't being returned:
		// Query: select * from storeconf where storeent_id=41
		// 1. 'google.analytics.versions': 'UA,GA4'
		// 2. 'google.tag.manager.auth': 'cnzsj5FrFmQu8pJAeDsjbQ'
		// 3. 'google.tag.manager.container.id': 'GTM-5HTXBL2'
		// 4. 'google.tag.manager.preview': 'env-1'
		//
		const _settings = settings ?? pageProps.settings;
		const { ua, ga4, gtmId, gtmAuth, gtmPreview } = getGTMConfig(_settings);
		if (ua || ga4) {
			initializeGTM(gtmId, gtmAuth, gtmPreview);
		}
	}, [pageProps, settings]);

	const BV_URL = useMemo(() => {
		if (settings?.storeId === '10851' && settings?.customEnvVariables?.bVEnvironment) {
			return `https://apps.bazaarvoice.com/deployments/${BV_CLIENT_NAME_10851}/${BV_SITE_ID_10851}/${settings.customEnvVariables.bVEnvironment}/${BV_LOCALE_10851}/bv.js`;
		} else if (settings?.storeId === '10852') {
			return `https://apps.bazaarvoice.com/deployments/${BV_CLIENT_NAME_10852}/${BV_SITE_ID_10852}/${settings.customEnvVariables.bVEnvironment}/${BV_LOCALE_10852}/bv.js`;
		} else if (settings?.storeId === '10853') {
			return `https://apps.bazaarvoice.com/deployments/${BV_CLIENT_NAME_10853}/${BV_SITE_ID_10853}/${settings.customEnvVariables.bVEnvironment}/${BV_LOCALE_10853}/bv.js`;
		}
	}, [settings?.customEnvVariables?.bVEnvironment, settings?.storeId]);

	const segmentKey = useMemo(() => {
		if (settings?.customEnvVariables?.segmentKey && settings?.storeId) {
			const segmentKeysMap = settings.customEnvVariables.segmentKey;
			let storeKey = '';
			if (segmentKeysMap && segmentKeysMap.includes(settings?.storeId)) {
				segmentKeysMap.split(',').forEach((segmentKeys: string) => {
					const segmentKeyPair = segmentKeys.trim().split(':');
					if (segmentKeyPair[0].trim() === settings?.storeId) {
						storeKey = segmentKeyPair[1].trim();
					}
				});
			}
			return storeKey;
		}
	}, [settings?.customEnvVariables?.segmentKey, settings?.storeId]);

	const klaviyoURL = useMemo(() => {
		if (settings?.storeId === '10851') {
			return `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_ID_10851}`;
		} else if (settings?.storeId === '10852') {
			return `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_ID_10852}`;
		} else if (settings?.storeId === '10853') {
			return `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_ID_10853}`;
		}
	}, [settings?.storeId]);

	return (
		<>
			<SettingProvider value={settings ?? EMPTY_SETTINGS}>
				{settings?.csrSession ? <Script src="/iframeResizer.contentWindow.min.js" /> : null}
				<StateProvider>
					{settings?.inPreview ? <PreviewCommunication /> : null}
					<CacheProvider value={emotionCache}>
						<EventsProvider>
							<SessionErrorProvider>
								<NotificationsProvider>
									<GlobalSpinnerProvider>
										<HeaderAccountMenuProvider>
											<Component {...pageProps} />
										</HeaderAccountMenuProvider>
									</GlobalSpinnerProvider>
								</NotificationsProvider>
							</SessionErrorProvider>
						</EventsProvider>
					</CacheProvider>
				</StateProvider>
			</SettingProvider>
			{BV_URL ? <Script defer src={BV_URL} /> : null}
			{segmentKey ? (
				<Script id="segment-script">
					{`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentKey}";analytics.SNIPPET_VERSION="4.13.2";
						analytics.load("${segmentKey}");
						analytics.page();	 
					}}();`}
				</Script>
			) : null}
			{klaviyoURL ? <Script async src={klaviyoURL} /> : null}
			{settings?.customEnvVariables?.appDKey ? (
				<Script id="appd-script" defer type="text/javascript">
					{`window["adrum-start-time"] = new Date().getTime();
						(function(config){
							config.appKey = "${settings.customEnvVariables.appDKey}";
						config.adrumExtUrlHttp = "https://www.wacoal-america.com/wcsstore/WacoalStorefrontAssetStore/upload/appd/jsagent";
						config.adrumExtUrlHttps = "https://www.wacoal-america.com/wcsstore/WacoalStorefrontAssetStore/upload/appd/jsagent";
						config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
						config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
						config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
						config.maxUrlLength = 512;
							})(window["adrum-config"] || (window["adrum-config"] = { }));`}
				</Script>
			) : null}
			{settings?.customEnvVariables?.appDKey ? (
				<Script src="https://www.wacoal-america.com/wcsstore/WacoalStorefrontAssetStore/upload/appd/jsagent/adrum/adrum.js"></Script>
			) : null}
		</>
	);
};

export default MyApp;
